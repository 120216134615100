.topSec {
  float: left;
  width: 100%;
  height: auto;
  background: #ffffff;
  padding-top: 2rem;
  background: linear-gradient(to top, #ffffff 30%, #4b4aee 30%, #4b4aee 30%);
}

.nTop h2 {
  font-size: 2rem;
  font-weight: bold;
  color: #fff;
  text-align: center;
  padding-bottom: 0.5rem;
}

.hrCus {
  color: #d0d2ff;
  background-image: linear-gradient(to left, white 2%, #d0d2ff 50%, white 98%);
}

.schoolDetails {
  max-width: 100%;
  width: 100%;
  margin: 0 auto;
  color: #d6cdff;
  height: auto;
  width: 100%;
  font-size: 1.4rem;
  text-align: center;
}

.headinSub {
  max-width: 70%;
  margin: 0 auto;
  color: #d6cdff;
  text-align: center;
  font-size: 1rem;
  padding-bottom: 0;
}

.schoolDetails {
  max-width: 100%;
  width: 100%;
  margin: 0 auto;
  color: #fff;
  height: auto;
  width: 100%;
  font-size: 1.4rem;
  text-align: center;
}

.schoolDetails li {
  margin: 0.5rem 0.8rem;
}

.ban {
  max-width: 100%;
  width: 100%;
  margin: 2rem auto;
  position: relative;
}

.banCon {
  max-width: 98%;
  width: 100%;
  margin: 1rem auto;
  position: absolute;
  height: auto;
  bottom: 0;
  left: 1%;
  background: rgba(0, 0, 0, 0.5);
  padding: 1rem 1rem 2rem 1rem;
  z-index: 1;
}

.banCon ul {
  max-width: 70%;
  width: 100%;
  margin-left: 15%;
}

.ban img {
  max-width: 100%;
  width: 100%;
  border: 10px solid #fff;
  -webkit-box-shadow: 0px 3px 14px 0px rgba(0, 0, 0, 0.26);
  -moz-box-shadow: 0px 3px 14px 0px rgba(0, 0, 0, 0.26);
  box-shadow: 0px 3px 14px 0px rgba(0, 0, 0, 0.26);
}

.blue-text {
  color: #00004f;
}

strong {
  font-weight: 700;
}

.whiteButton {
  text-decoration: none;
  margin-top: 1.5rem;
  display: inline-block;
  color: #26006c;
  border: 2px solid rgb(255, 255, 255);
  border-radius: 0px;
  padding: 1rem 2rem;
  display: inline-block;
  cursor: pointer;
  box-shadow: inset 0 0 0 0 #fff;
  -webkit-transition: ease-out 0.4s;
  -moz-transition: ease-out 0.4s;
  transition: ease-out 0.4s;
  border-radius: 3rem;
}

.whiteButton:hover {
  box-shadow: inset 400px 0 0 0 #fff;
  color: #4b4aee;
}

.skyButton {
  text-decoration: none;
  color: #26006c;
  border: 2px solid #4b4aee;
  border-radius: 0px;
  padding: 0.5rem 1rem;
  display: inline-block;
  cursor: pointer;
  box-shadow: inset 0 0 0 0 #4b4aee;
  -webkit-transition: ease-out 0.4s;
  -moz-transition: ease-out 0.4s;
  transition: ease-out 0.4s;
  border-radius: 3rem;
  font-size: 18px !important;
}

.skyButton:hover {
  box-shadow: inset 400px 0 0 0 #4b4aee;
  color: #fff;
}

.chartCon {
  display: flex;
}

.secGap {
  padding: 3rem 0;
  float: left;
  width: 100%;
  margin-top: 3rem;
  background-color: #f9f9f9;
}

.tex-center {
  text-align: center;
}

.assText {
  color: #333333;
}

.skText {
  color: #4b4aee;
}

p {
  font-size: 1.2rem;
  line-height: 1.8rem;
  padding: 0rem 0 1rem 0;
  color: #4e4d4d;
  font-weight: 300;
}
.cardCusGap {
  margin-top: 4rem;
}

.cusMarLeft {
  margin-left: 5%;
}

.cusMarRight {
  margin-right: 5%;
}

/*.card  { flex-shrink: none;}*/
.cardBodyCus {
  padding-top: 0;
}

.cusList li {
  margin-top: 0.5rem;
  font-size: 1.2rem;
  font-weight: 500;
  color: #4e4d4d;
}

.cusList li i {
  background-color: #fff;
  border: 1px solid #9c9c9c;
  color: #4b4aee;
  font-size: 1rem;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.sec {
  position: relative;
  width: 100%;
  height: auto;
  z-index: 1;
}

.sec img {
  max-width: 100%;
  width: 100%;
}

.sec::after {
  position: absolute;
  width: 75px;
  height: 75px;
  content: ' ';
  z-index: -1;
  background: #4b4aee;
  right: -2%;
  bottom: -2%;
}

.sec::before {
  position: absolute;
  width: 75px;
  height: 75px;
  content: ' ';
  z-index: -1;
  background: #4b4aee;
  left: -2%;
  top: -2%;
}

.sbutton {
  background: #4b4aee;
  color: #fff;
  border: none;
  position: relative;
  height: 3rem;
  font-size: 1.4rem;
  cursor: pointer;
  transition: 500ms ease all;
  outline: none;
  text-decoration: none;
  padding: 0.5rem 1rem;
  font-weight: 300;
}

.sbutton:hover {
  background: #fff;
  color: #4b4aee;
}

.sbutton:before,
.sbutton:after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  height: 2px;
  width: 0;
  background: #4b4aee;
  transition: 400ms ease all;
}

.sbutton:after {
  right: inherit;
  top: inherit;
  left: 0;
  bottom: 0;
}

.sbutton:hover:before,
.sbutton:hover:after {
  width: 100%;
  transition: 800ms ease all;
}

.w-90 {
  width: 90%;
  margin: 0 auto;
}

.skySec {
  background-color: #4b4aee;
  padding: 3rem 0;
  float: left;
  width: 100%;
}

.skySec .tabel {
  background-color: #4b4aee;
  padding: 3rem 0;
  float: left;
  width: 100%;
}

.whiteTabel {
  margin-top: 1.6rem;
}

.whiteTabel th {
  color: #4b4aee;
  font-size: 1.6rem;
  font-weight: 600;
  border: 1px dashed #4b4aee;
}

.whiteTabel td {
  /* padding: 1.5rem 0; */
  font-size: 1.4rem;
  font-weight: 300;
  color: #494949;
  border: 1px dashed #6b6b6b;
}

.whiteSec {
  background-color: #fff;
  padding: 3rem 0;
  float: left;
  width: 100%;
}

.chartCon img {
  max-width: 100%;
  width: 100%;
}

.whiteText {
  color: #fff;
}

.certificateImg {
  margin-top: 3rem;
}

.certificateImg img {
  border: 5px solid #ffffff;
  -webkit-box-shadow: 0px 3px 14px 0px rgba(0, 0, 0, 0.26);
  -moz-box-shadow: 0px 3px 14px 0px rgba(0, 0, 0, 0.26);
  box-shadow: 0px 3px 14px 0px rgba(0, 0, 0, 0.26);
}

hr {
  border: 0;
  height: 1px;
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.75),
    rgba(0, 0, 0, 0)
  );
}

/***********************  New Css Plz Add ***************/
.bluebutton {
  background: #4b4aee;
  color: #fff;
  border: none;
  position: relative;
  /* height: 3rem;  */
  font-size: 1.4rem;
  cursor: pointer;
  transition: 500ms ease all;
  outline: none;
  text-decoration: none;
  padding: 0.5rem 0;
  font-weight: 300;
  width: 10rem !important;
  display: inline-block;
}

.bluebutton:hover {
  background: #fff;
  color: #4b4aee;
}

.bluebutton:before,
.bluebutton:after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  height: 2px;
  width: 0;
  background: #4b4aee;
  transition: 400ms ease all;
}

.bluebutton:after {
  right: inherit;
  top: inherit;
  left: 0;
  bottom: 0;
}

.bluebutton:hover:before,
.bluebutton:hover:after {
  width: 100%;
  transition: 800ms ease all;
}

.greenbutton {
  background: #009b1c;
  color: #fff;
  border: none;
  position: relative;
  /* height: 3rem;  */
  font-size: 1.4rem;
  cursor: pointer;
  transition: 500ms ease all;
  outline: none;
  text-decoration: none;
  padding: 0.5rem 0;
  font-weight: 300;
  width: 10rem !important;
  display: inline-block;
}

.greenbutton:hover {
  background: #fff;
  color: #009b1c;
}

.greenbutton:before,
.greenbutton:after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  height: 2px;
  width: 0;
  background: #009b1c;
  transition: 400ms ease all;
}

.greenbutton:after {
  right: inherit;
  top: inherit;
  left: 0;
  bottom: 0;
}

.greenbutton:hover:before,
.greenbutton:hover:after {
  width: 100%;
  transition: 800ms ease all;
}

.seper {
  display: block;
  padding: 2rem 0 0rem 0;
  margin-top: 2rem;
  border-top: 1px solid #ccc;
}
