.card-title {
  font-weight: 700;
  font-size: 16px;
}

.card-text {
  margin-top: 10px;
}

.blacknav .krmNah {
  margin: 5px;
  background-color: #000 !important;
  height: 13px !important;
  width: 13px !important;
  box-shadow: none !important;
}

.blacknav .jhmYzC {
  border: 1px solid #000 !important;
  box-shadow: none !important;
  height: 13px !important;
  width: 13px !important;
}

.video-wrapper {
  height: 100%;
  width: 100%;
  min-height: 350px !important;
}
