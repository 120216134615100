.progressBar-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

@media (max-width: 1600px) {
  .progressBar-container {
    flex-wrap: wrap;
  }

  .bar__chart__container {
    flex-wrap: wrap;
  }
}

@media (max-width: 1200px) {
  .progressBar-container {
    flex-wrap: wrap;
  }
}

@media (max-width: 992px) {
  .trending__learning__sub__container div {
    margin: 10px;
  }

  .trending__learning__sub__container {
    flex-wrap: wrap;
  }
}

@media (max-width: 768px) {
  .trending__learning__sub__container div {
    margin: 10px;
  }

  .trending__learning__sub__container {
    flex-direction: column;
  }
}

.classroom__header__description {
  height: 60px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.6);
  flex: none;
  order: 0;
  flex-grow: 0;
}

.progress__bar__container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.progress__bar__heading {
  width: 160px;
  height: 20px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  flex: none;
  order: 0;
  flex-grow: 0;
  text-align: center;
}

.progress__bar__footer2 {
  left: 29.75%;
  right: 61.09%;
  top: 18.03%;
  bottom: 81.47%;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: -0.02em;
  color: #001103;
  opacity: 0.5;
}

.progress__bar__footer1 {
  width: 89px;
  height: 17px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.02em;
  color: #ffa934;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.score__comparison__container {
  display: flex;
  padding: 20px;
  margin-left: 75px;
}

.score__comparison__sub__container {
  width: 45%;
  display: flex;
  flex-direction: column;
}

.score__comparison__sub__container h5 {
  width: 160px !important;
  height: 14px !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 20px !important;
  color: #102a13 !important;
  flex: none !important;
  order: 0 !important;
  flex-grow: 0 !important;
  margin-top: 35px !important;
  margin-bottom: 0.5rem;
}

.score__comparison__container p {
  width: 233px;
  height: 20px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  color: rgb(26, 31, 169);
}

/* .score__comparison__container p:hover {
    color: rgb(26, 31, 169);
    text-shadow: 2px 2px 4px rgba(172, 9, 226, 0.5);
    font-size: 14.3px;
} */

.class__room__bar__chart {
  border-radius: 15px;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);
  margin: 10px;
  width: auto;
}

.testimonial-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);
  width: 278px;
  height: 347px;
  margin: 10px;
  border: 1px solid rgba(0, 0, 0, 0.15);
}

.testimonials {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.testimonial-image {
  width: 136px;
  height: 136px;
  border-radius: 50%;
  object-fit: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

.testimonial-image img {
  text-align: center;
  display: flex;
  flex-direction: column;
}

.testimonial-name {
  font-size: 18px;
  font-weight: bold;
}

.testimonial-role {
  font-size: 14px;
  color: #3bee23;
  margin-top: 5px;
  /* text-align: center; */
}

.testimonial-content {
  text-align: center;
}

.testimonial-description {
  font-size: 14px;
  line-height: 1rem;
  padding: 0rem 0 0.1rem 0;
  color: #4e4d4d;
  font-weight: 300;
}

.class__room__bar__chart {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 15px;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);
  margin: 10px;
  width: auto;
}

.class__room__bar__chart__top h1 {
  font-size: 16px;
  padding-left: 30px;
}

.class__room__bar__chart__top {
  /* width: 300px; */
  background-color: #1788d4;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  height: 50px;
  display: flex;
  align-items: center;
}

.bar__chart__container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.modal__container {
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal__card {
  display: flex;
  width: 50%;
  min-width: 300px;
  height: 60%;
  min-height: 200px;
  background-color: white;
  padding: 5px;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.micro__parameter {
  width: 70%;
  height: 100%;
  margin: auto;
  padding: 10px;
}

.micro__parameter h5 {
  color: #000000 !important;
  font-size: 20px;
  font-weight: 400;
  padding: 2px;
}

.modal__button {
  width: 100%;
  color: black !important;
  text-align: right;
}
