.modal-dialog {
  max-width: 100% !important;
  margin: 1.25rem auto;
}

.modal {
  position: fixed;
  top: 2%;
  left: 10%;
  display: flex;
  z-index: 1050;
  display: none;
  /* overflow: hidden; */
  outline: 0;
  padding: 20px;
  /* right: 10%;*/
}

.border-radius-2,
.modal-content {
  border-radius: 20px !important;
}

.modal-header {
  border-radius: 20px 20px 0px 0px !important;
}

button.close {
  border: 0px;
  border-radius: 50%;
  background: transparent;
  color: #fff;
}

.bar-pie-text {
  font-size: 20px;
  font-weight: 600;
  padding-left: 10px;
}

.barchart-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media (max-width: 767px) {
  .barchart-container {
    flex-direction: column;
    justify-content: center;
  }
}
