.edutabel {
  display: flex;
  margin-top: 50px;
}
.edutabel div.left {
  display: flex;
  flex: 3;
  background-color: #fff;
  padding: 15px;
}
.edutabel div.right {
  display: flex;
  flex: 1;
  background-color: #fff;
  margin-left: 2px;
  padding: 15px;
}

.l-sec {
  display: flex;
  width: 100%;
}
.l-sec div.l-sec-l {
  flex: 5;
  align-items: center;
  display: flex;
  align-content: center;
}
.l-sec-l h5 {
  border-right: 2px solid #0d6efd;
  padding: 30px;
}
.l-sec div.l-sec-r {
  flex: 7;
  padding: 30px;
}
.list-group-item span {
  color: #000;
  font-weight: bold;
  text-align: right;
  float: right;
}
.list-group-item {
  border: none;
}
.right div {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 30px;
}

@media (max-width: 767px) {
}
