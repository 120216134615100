.card-title {
  font-weight: 700;
  font-size: 16px;
}

.card-subtitle {
  color: #76d27f;
  font-weight: 700;
}

.card-text {
  margin-top: 10px;
}

.krmNah {
  margin: 5px;
  background-color: #fff !important;
  height: 13px !important;
  width: 13px !important;
  box-shadow: none !important;
}

.jhmYzC:hover,
.jhmYzC:focus {
  cursor: pointer;
  box-shadow: none !important;
}

.jhmYzC {
  border: 1px solid #fff !important;
  box-shadow: none !important;
  height: 13px !important;
  width: 13px !important;
}

.hHJTFD {
  margin: 0px !important;
}

.card-img,
.card-img-bottom,
.card-img-top {
  width: 100%;
  border-radius: 10px 10px 0 0 !important;
}

.card {
  border-radius: 10px !important;
}
