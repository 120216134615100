/****** Video & Pdf ********/
button {
  color: #2154ba;
  border: 0px;
  padding: 10px;
  background: transparent;
  font-weight: 600;
}

.videoPdfCon {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 2rem;
}

.videoPdfCon h2 {
  display: flex;
  flex-direction: column;
  width: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-bottom: 1rem;
}

.docPdfWrap {
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
}

.docPdfWrap div {
  width: 22%;
  display: flex;
  flex-direction: column;
  height: auto;
  gap: 14px;
}

.docPdfWrap div div {
  max-width: 100%;
  width: 100%;
  border-radius: 25px;
  margin-bottom: 0;
}

.docPdfWrap div h4 {
  font-size: 1.3rem;
  font-weight: 700;
  margin: 1.5rem 0 0.5rem 0;
}

.docPdfWrap div p {
  margin-top: 0;
  padding-top: 0;
}

.docPdfWrap div a {
  display: block;
  border-radius: 25px;
  border: 1px solid #27963c;
  text-align: center;
  padding: 10px 0;
  text-align: center;
  text-decoration: none;
  color: #27963c;
  font-weight: 600;
  transition: 1s;
}

.docPdfWrap div a:hover {
  background-color: #27963c;
  color: #fff;
}

@media (max-width: 767px) {
  .docPdfWrap {
    flex-direction: column;
  }

  .docPdfWrap div {
    width: 96%;
  }
}

.img__container {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);
  margin: 10px;
  width: 300px;
  height: 289px;
}

.img__container img {
  border-radius: 15px;
  margin: 10px;
  width: 300px;
  height: 289px;
}

tbody,
th,
tr,
td {
  border: 1px solid #ccc;
}

.aspiring__video__pdf__container {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);
  margin: 10px;
  width: 233px;
  height: 289px;
}

.tableFixHead {
  overflow-y: auto;
}

.tableFixHead thead th {
  position: sticky;
  /* make the table heads sticky */
  top: 0px;
  /* table head will be placed from the top of the table and sticks to it */
}

.bottomDivs {
  margin-top: 20px;
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.description-text {
  font-size: 0.8rem;
  line-height: 1rem;
}
