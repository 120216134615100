.container-fluid {
  overflow-y: scroll;
}

h2,
h2 {
  font-size: 1.7rem;
  font-weight: 600;
}

.readinessCon {
  display: flex;
  flex-direction: column;
  padding: 1.5rem 0;
}

.readHeadingCon {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.readRight {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}

.readRight h4 {
  font-size: 15px;
  font-weight: 700;
  margin-bottom: 0;
  color: #001103;
}

.readRight p {
  font-size: 13px;
  font-weight: 600;
  color: #828282;
  margin-bottom: 0;
  padding-bottom: 0;
}

.profile img {
  max-width: 50px;
  border-radius: 50%;
}

.readRight .dropdown-toggle::after {
  display: none;
}

.readRight i {
  color: #001103;
}

.readMain {
  display: flex;
  flex-direction: column;
  padding: 1.5rem 0;
}

.readMain p {
  color: #666666;
  line-height: 30px;
}

.readMain p strong {
  color: #000000;
  line-height: 30px;
}

.readMain p:nth-last-child(1) {
  margin-bottom: 0;
}

.readSearch {
  max-width: 250px;
  width: 100%;
  margin-right: 20px;
}

#custom-search-input {
  margin: 0;
  margin-top: 10px;
  padding: 0;
}

#custom-search-input .search-query {
  padding-right: 3px;
  padding-right: 4px \9;
  padding-left: 3px;
  padding-left: 4px \9;
  margin-bottom: 0;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 24px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #001103;
}

#custom-search-input button {
  border: 0;
  background: none;
  padding: 2px 5px;
  margin-top: 9px;
  position: relative;
  left: -38px;
  margin-bottom: 0;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 0px;
  color: #7f8780;
  border-left: 1px solid #7f8780;
  padding-left: 10px !important;
}

.dropdownCus {
  margin-bottom: 0;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 24px;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #27963c;
  border: 1px solid #27963c;
}

.dropdownCus a {
  text-decoration: none;
  color: #27963c;
}

.dropdown-cus {
  justify-content: center;
  color: #27963c;
  border: 1px solid #27963c;
  margin-top: 8px !important;
  left: -61px !important;
  width: 100% !important;
  padding: 0;
}

.search-query:focus + button {
  z-index: 3;
}

::placeholder {
  color: #001103;
  font-weight: 700;
  opacity: 1;
}

:-ms-input-placeholder {
  color: #001103;
  font-weight: 700;
}

::-ms-input-placeholder {
  color: #001103;
  font-weight: 700;
}

.form-control::placeholder {
  color: #1e1e1e;
  opacity: 1;
}

.endlineComparison {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.endlineComparison h2 {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.endlineComparison p {
  color: #828282;
  margin-bottom: 0;
  padding-bottom: 0;
}

.endlineComparison {
  display: flex;
  flex-direction: column;
  padding: 1.5rem 0;
}

.endlineComparison p {
  margin-bottom: 0;
  padding-bottom: 0;
  color: #666666;
  line-height: 30px;
}

.endlineComparison p:nth-last-child(1) {
  margin-bottom: 0;
}

.improvment {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.improvment h2 {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
}

.improvmentCon {
  display: flex;
  flex-direction: column;
  padding: 1.5rem 0;
}

.improvmentCon p {
  margin-bottom: 0;
  padding-bottom: 0;
  color: #666666;
  line-height: 30px;
}

.improvmentCon p:nth-last-child(1) {
  margin-bottom: 0;
}

.perfonceCon {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.perfonceCon h2 {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.chartCon {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 2rem;
  flex-wrap: wrap;
}

.bar__chart {
  border-radius: 15px;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);
  margin: 10px;
}

.chartCoTwo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 2rem;
  flex-wrap: wrap;
  gap: 1rem;
}

.chartCoTwo div {
  width: auto;
}

.chartCoTwo div img {
  max-width: 100%;
  width: 100%;
}

@media (max-width: 767px) {
  .bar__chart {
    flex-direction: column;
  }

  .chartCoTwo {
    flex-direction: column;
    justify-content: center;
  }
}

.bar__chart h1 {
  font-size: 20px;
  padding-left: 30px;
}

.bar__chart__top {
  width: inherit;
  background-color: #c0e3fb;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  height: 50px;
  display: flex;
  align-items: center;
}

table {
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.table__heading:first-child {
  border-top-left-radius: 10px;
}

.table__heading:last-child {
  border-top-right-radius: 10px;
  border-right: 0;
}

.pie__chart__card {
  width: 300px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 10px;
  padding: 10px;
}

.pie__chart__card__content {
  display: flex;
  flex-direction: column;
  /* align-items: center;
    justify-content: center;
    padding-left: 100px; */
}

@media (max-width: 768px) {
  .pie__chart__card {
    width: 100%;
  }

  .pie__chart__card .recharts-surface {
    width: 300px;
  }

  .pie__chart__card {
    width: 350px !important;
    margin-left: 100px;
  }

  .pie__chart__card .recharts-default-legend {
    width: 300px !important;
  }

  .pie__chart__card .recharts-wrapper {
    width: 300px !important;
  }
}

.area__chart__container {
  width: 300px;
  display: flex;
  border-radius: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 10px;
  padding: 20px;
}

.area__chart__sub__container1 {
  width: 60%;
  display: flex;
  flex-direction: column;
}

.area__chart__sub__container2 {
  /* width: 40%; */
  display: flex;
  flex-direction: column;
}

.snapshot {
  font-size: 12px;
}
