.nav-tabs-custom {
  border-bottom: none;
  display: inline-flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: #fff;
  border: 2px solid #fff;
  border-radius: 34px;

  font-size: 22px;
  font-weight: 600;
  width: auto;
}
.nav-tabs-custom a {
  color: #fff;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #fff;
  background-color: #4a4ac6;
  border-color: #fff;
  border: 0px 2px 0px 2px !important;
  border-radius: 34px;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link:hover {
  color: #fff;
  background-color: #4a4ac6;
  border-color: #fff;
  border: 0px 4px 0px 4px !important;
  border-radius: 34px;
}
.nav-link {
  padding: 0.5rem 3rem;
}
.nav-tabs .nav-link {
  margin-bottom: -1px;
  background: 0 0;
  border: 2px solid transparent;
  border-radius: 34px;
}
