.header-nav-btn {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.login-padding {
  padding: 10vh 0;
}
.header-nav-btn-primary-new {
  color: #fff;
  text-align: center;
  margin-right: 20px;
  background-color: #653d22;
  border: #653d22;
  padding: 6px 40px;
  border-radius: 25px;
  margin-top: 20px;
}

body::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
body {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.header-nav-btn-primary {
  color: #fff;
  text-align: center;
  margin-right: 20px;
  background-color: #653d22;
  border: #653d22;
  border-radius: 25px;
  margin-top: 20px;
  min-width: 130px;
  padding: 10px 20px;
}

.formDiv {
  background-color: white;
  padding: 40px 25px;
  border-radius: 10px;
}

.input-text-box {
  width: 80%;
  margin-top: 5px;
}

.input-padding {
  padding: 10px 0;
}

.input-login-padding {
  padding: 10px 25px;
}

.input-textbox {
  width: 80%;
  min-height: 100px;
  margin-top: 0px;
}

.label-span {
  margin-top: 20px;
  margin-bottom: 0px;
}

.neeve-btn {
  background-color: #653d22;
  border: #653d22;
  padding: 6px 40px;
  border-radius: 25px;
  margin-bottom: 15px;
  color: #fff;
}

.singnupbg {
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.more-info {
  text-align: right;
  padding-right: 10%;
}

.more-info .col a {
  color: #653d22;
  text-decoration: none;
}

.error-msg {
  color: red;
  display: none;
  text-align: left !important;
  margin-left: 10%;
  font-size: 1rem;
}

.select-box-udise {
  left: 10%;
}

.ctn img {
  width: 100%;
  height: auto;
}

.ctn .btn {
  position: absolute;
  top: 75%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);

  font-size: 20px;

  cursor: pointer;
  text-align: center;
  color: #fff;

  text-align: center;

  margin-right: 20px;
  background-color: #653d22;
  border: #653d22;
  padding: 8px 70px;
  border-radius: 25px;
  margin-top: 20px;
}

.ctn .btn2 {
  position: absolute;
  top: 75%;
  left: 66%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);

  font-size: 20px;

  cursor: pointer;
  text-align: center;
  color: #653d22;

  text-align: center;
  border-style: solid;
  border-width: thin;
  margin-right: 20px;
  background-color: white;
  border: #653d22;
  padding: 8px 70px;
  border-radius: 25px;
  margin-top: 20px;
  text-decoration: none;
  border-style: solid;
  border-width: thin;
}

.ctn .btn:hover {
  background-color: #54331d;
}

form svg {
  margin-left: -30px;
  cursor: pointer;
}

.resend-link {
  cursor: pointer;
  color: #54331d;
}

/* Modal */
/* .modal {
  display: none;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
} */

/* Modal Content/Box */
/* .modal-content {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
} */

/* The Close Button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}
.header-nav-span {
  margin-top: 20px;
  display: inline-block;
}

.bg-grey {
  padding: 0px;
  background-color: #f2f2f2;
  min-height: 500px;
  margin: 0px;
}

.left-menu {
  margin: 0px 10px;
  background-color: white;
  padding-right: 0px;
  padding-left: 10px;
  min-width: 179px;
}

.right-menu {
  margin: 5px 15px;
  background-color: white;
  padding: 20px 12px;
}

.comment-user-logo {
  background-color: violet;
  color: white;
  border-radius: 50px;
  text-align: center;
  padding: 15px;
}

.left-menu-icon {
  display: inline-block;
  width: 16.3px;
  height: 13.45px;
  overflow: hidden;
}

.right-menu .row .create-btn-div {
  text-align: right;
}

.comment-user-logo {
  background-color: violet;
  color: white;
  border-radius: 50px;
  text-align: center;
  padding: 15px;
}

.comment-box {
  background-color: #e0ece2;
  padding: 20px;
  border-radius: 0px 15px 15px 15px;
}

.comment-box-reply {
  background-color: #f7f6f6;
  padding: 20px;
  border-radius: 15px 0px 15px 15px;
}

.comment-box-input-textbox {
  width: 100%;
  min-height: 80px;
  border-radius: 15px;
  border-color: grey;
  border-style: groove;
}

.comment-box-input {
  text-align: right;
}

.video-container div iframe {
  width: 100%;
}

.min-height {
  min-height: 500px;
}

.left-menu-btn h5 {
  font-size: 15px;
}

.left-menu-img {
  width: 32px;
}

.left-menu-hr {
  margin: 3px 0;
}

#dropdown-basic {
  background-color: white;
  border-color: white;
}

@media only screen and (max-width: 1242px) {
  .ctn .btn2 {
    left: 68%;
  }
  .cardd-body {
    width: 93%;
    border-top: 1px solid grey;
    margin-bottom: -8%;
  }

  .carddd-title {
    margin-left: 21%;
    margin-top: -17%;
  }

  .carddddd-text {
    margin-left: 22%;
    width: 60%;
    margin-top: -5%;
  }

  .carda-img-top {
    width: 25%;
    margin-top: -55px;
    margin-left: -6%;
    height: 20%;
    padding-bottom: 10px;
  }
}

@media only screen and (max-width: 1110px) {
  .ctn .btn2 {
    left: 71%;
  }
  .tet {
    width: 568px;

    margin-top: 1%;
  }

  .imgggg {
    width: 484px;
    height: 480px;
    margin-top: -3px;
  }
  .input-text-boxx {
    width: 100%;
  }
  .label-spanx1 {
    margin-top: 10%;
    margin-left: -1%;
  }
  .input-text-boxx1 {
    width: 100%;
    margin-left: -1%;

    margin-top: -1%;
  }
  .input-text-boxx2 {
    width: 100%;
    margin-left: -1%;

    margin-top: -1%;
  }
  .label-spanx2 {
    margin-top: 10%;
    margin-left: -1%;
  }
  .input-text-box3 {
    width: 100%;
    margin-left: -1%;

    margin-top: -1%;
  }
  .label-spanx3 {
    margin-top: 10%;
    margin-left: -1%;
  }
  .btnx {
    margin-left: 15%;
    width: 67%;
    margin-top: 14%;
    margin-bottom: 17%;
  }
}

@media only screen and (max-width: 950px) {
  .ctn .btn {
    font-size: 15px;
  }

  .ctn .btn2 {
    font-size: 15px;
    left: 75%;
  }
  .tet {
    width: 400px;
    margin-left: 1%;
    margin-top: 1%;
  }
  .tet {
    width: 568px;

    margin-top: 1%;
  }
  .imgggg {
    width: 602px;
    height: 549px;
    margin-top: -23px;
    margin-left: -7px;
  }
  .input-text-boxx {
    width: 100%;
  }
  .label-spanx1 {
    margin-top: 10%;
    margin-left: -1%;
  }
  .input-text-boxx1 {
    width: 100%;
    margin-left: -1%;

    margin-top: -1%;
  }
  .input-text-boxx2 {
    width: 100%;
    margin-left: -1%;

    margin-top: -1%;
  }
  .label-spanx2 {
    margin-top: 10%;
    margin-left: -1%;
  }
  .input-text-box3 {
    width: 100%;
    margin-left: -1%;

    margin-top: -1%;
  }
  .label-spanx3 {
    margin-top: 10%;
    margin-left: -1%;
  }
  .btnx {
    margin-left: 15%;
    width: 67%;
    margin-top: 14%;
    margin-bottom: 17%;
  }
}

@media only screen and (max-width: 744px) {
  .ctn .btn {
    font-size: 15px;
    padding: 8px 50px;
  }

  .ctn .btn2 {
    font-size: 15px;
    padding: 8px 50px;
    left: 75%;
  }
  .tet {
    width: 568px;

    margin-top: 1%;
  }
  .imgggg {
    width: 602px;
    height: 549px;
    margin-top: -23px;
    margin-left: -7px;
  }
  .input-text-boxx {
    width: 100%;
  }
  .label-spanx1 {
    margin-top: 10%;
    margin-left: -1%;
  }
  .input-text-boxx1 {
    width: 100%;
    margin-left: -1%;

    margin-top: -1%;
  }
  .input-text-boxx2 {
    width: 100%;
    margin-left: -1%;

    margin-top: -1%;
  }
  .label-spanx2 {
    margin-top: 10%;
    margin-left: -1%;
  }
  .input-text-box3 {
    width: 100%;
    margin-left: -1%;

    margin-top: -1%;
  }
  .label-spanx3 {
    margin-top: 10%;
    margin-left: -1%;
  }
  .btnx {
    margin-left: 15%;
    width: 67%;
    margin-top: 14%;
    margin-bottom: 17%;
  }
}

@media only screen and (max-width: 580px) {
  .ctn .btn {
    font-size: 15px;
    padding: 5px 30px;
  }

  .ctn .btn2 {
    font-size: 15px;
    padding: 5px 30px;
    left: 75%;
  }
  .tet {
    width: 300px;

    margin-top: 1%;
  }
  .imgggg {
    width: 388px;
    height: 347px;
    margin-top: -23px;
    margin-left: -23px;
  }
  .input-text-boxx {
    width: 100%;
  }
  .label-spanx1 {
    margin-top: 10%;
    margin-left: -1%;
  }
  .input-text-boxx1 {
    width: 100%;
    margin-left: -1%;

    margin-top: -1%;
  }
  .input-text-boxx2 {
    width: 100%;
    margin-left: -1%;

    margin-top: -1%;
  }
  .label-spanx2 {
    margin-top: 10%;
    margin-left: -1%;
  }
  .input-text-box3 {
    width: 100%;
    margin-left: -1%;

    margin-top: -1%;
  }
  .label-spanx3 {
    margin-top: 10%;
    margin-left: -1%;
  }
  .btnx {
    margin-left: 15%;
    width: 67%;
    margin-top: 14%;
    margin-bottom: 17%;
  }

  .colll {
    width: 98%;
    padding: 55px;
    /* border: 1px solid rgb(217, 212, 212); */
    margin: 20px;
  }
  .cardd-body {
    width: 93%;
    border-top: 1px solid grey;
    margin-bottom: -8%;
  }

  .carddd-title {
    margin-left: -24%;
    margin-top: 12%;
  }

  .carddddd-text {
    margin-left: -26%;
    width: 110%;
    margin-top: -25%;
  }

  .carda-img-top {
    width: 111%;
    margin-top: -65px;
    margin-left: -23%;
    height: 20%;
    padding-bottom: 17px;

    height: 20%;
    padding-bottom: 10px;
  }

  .btnm {
    margin-left: 94%;
    border-radius: 80% 80%;
  }

  .btnm:hover {
    background-color: #fff;
  }
  span.btn.btnSearch {
    margin-left: 1%;
    margin-top: -1%;
  }
}

@media only screen and (max-width: 425px) {
  .ctn .btn {
    font-size: 12px;
    padding: 2px 20px;
    top: 65%;
  }
  span.btn.btnSearch {
    margin-left: 2% !important ;
    margin-top: 0% !important;
  }
  .carda-img-top {
    width: 162% !important;
    margin-top: -35px !important;
    margin-left: -47% !important;
    height: 18% !important;
    padding-bottom: 23px !important;
  }
  .carddddd-text {
    margin-left: -57% !important;
    width: 186% !important;
    margin-top: -60% !important;
  }
  .btnm {
    margin-left: 89% !important;
    border-radius: 100% !important;
  }
  .carddd-title {
    margin-left: -47% !important;
    margin-top: -2% !important;
  }
  .ctn .btn2 {
    font-size: 12px;
    padding: 2px 20px;
    left: 82%;
    top: 65%;
  }
  .tet {
    width: 255px;
    margin-left: -2%;
    margin-top: 1%;
  }
  .imgggg {
    width: 288px;
    height: 321px;
    margin-top: -23px;
    margin-left: -40px;
  }
  .input-text-boxx {
    width: 100%;
  }
  .label-spanx1 {
    margin-top: 10%;
    margin-left: -1%;
  }
  .input-text-boxx1 {
    width: 100%;
    margin-left: -1%;

    margin-top: -1%;
  }
  .input-text-boxx2 {
    width: 100%;
    margin-left: -1%;

    margin-top: -1%;
  }
  .label-spanx2 {
    margin-top: 10%;
    margin-left: -1%;
  }
  .input-text-box3 {
    width: 100%;
    margin-left: -1%;

    margin-top: -1%;
  }
  .label-spanx3 {
    margin-top: 10%;
    margin-left: -1%;
  }
  .btnx {
    margin-left: 15%;
    width: 67%;
    margin-top: 14%;
    margin-bottom: 17%;
  }
  .colll {
    width: 98%;
    padding: 55px;
    /* border: 1px solid rgb(217, 212, 212); */
    margin: 20px;
  }
  .cardd-body {
    width: 93%;
    border-top: 1px solid grey;
    margin-bottom: -8%;
  }

  .carddd-title {
    margin-left: -24%;
    margin-top: -2%;
  }

  .carddddd-text {
    margin-left: -25%;
    width: 110%;
    margin-top: -45%;
  }

  .carda-img-top {
    width: 111%;
    margin-top: -65px;
    margin-left: -23%;
    height: 20%;
    padding-bottom: 17px;

    height: 20%;
    padding-bottom: 10px;
  }

  .btnm {
    margin-left: 94%;
    border-radius: 80% 80%;
  }

  .btnm:hover {
    background-color: #fff;
  }
  span.btn.btnSearch {
    margin-left: 35%;
    margin-top: -163%;
  }
}

.container-the-impact {
  padding: 25px;
  font-family: Helvetica !important;
}

.impact-details {
  background-color: #f2f2f2;
  border-radius: 50%;
  padding: 30px;
  margin: 15px 15px;
}

.impact-details h5 {
  font-weight: 700;
}

.container-admininstrators {
  padding: 25px;
  font-family: Helvetica !important;
  text-align: center;
  background-color: #f2f2f2;
}

.container-testimonials {
  padding: 25px;
  font-family: Helvetica !important;
  text-align: center;
}

.dot2,
.dot2:before,
.dot2:after {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background-color: grey;
}

.dot2 {
  margin-top: 5px;
  margin-left: 5px;
}

.dot2:before,
.dot2:after {
  content: '';
}

.dot2:before {
  right: 15px;
  transition: right 0.3s ease-out;
}

.dot2:after {
  left: 15px;
  transition: left 0.3s ease-out;
}

.dots2:hover .dot2:before {
  right: -15px;
}

.dots2:hover .dot2:after {
  left: -15px;
}

.left-menu-subheading {
  color: grey;
}

.left-menu-subheading h5 {
  font-size: 13px;
}

.path-selected {
  color: #27963c;
}

table th {
  background-color: #b0cfb7;
  padding: 10px;
}

table,
th,
td {
  border: 2px solid #fff;
}

tr:nth-child(even) {
  background-color: #f4f4f4;
}

tr td {
  padding: 10px;
}

td:nth-child(1) {
  font-weight: bold;
}

.panel {
  margin: 10px;
}

.boldspan {
  font-weight: bold;
}

.right-menu-nobg {
  margin: 5px 15px;
  padding: 10px 2px;
}

.rounded-pill-btn {
  border: none;
  padding: 2px 15px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 2px 2px;
  cursor: pointer;
  border-radius: 16px;
}

.vl {
  border-left: 2px solid #f2f2f2;
  height: 100%;
}

.vl2 {
  border-left: 1px solid #000;
  height: 1%;
}

.btnSearch {
  color: #000;
  background-color: #fff;
  border-top-color: #767676;
  border-right-color: #767676;
  border-bottom-color: #767676;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  min-height: 40px;
}

.serachtb {
  border-top-color: #767676;
  border-left-color: #767676;
  border-bottom-color: #767676;
  min-height: 40px;
}

.aspiring {
  color: #f7bf6b;
}

.scholar {
  color: #e00000;
}

.master {
  color: #4f83ef;
}

td {
  word-spacing: 6px;
}

span {
  word-spacing: 5px;
}

.comment-user-logo {
  background-color: violet;
  color: white;
  border-radius: 50px;
  text-align: center;
  padding: 15px;
}

.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
}

.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.path-selected {
  color: #27963c;
}

.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
}

.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* .btn-lesspad {
  padding: 0.375rem 0.25rem;
} */

.skycruv {
  width: 100%;
  /* height: auto; */
  display: block;
  background-color: #5193f4;
  clip-path: polygon(0 0, 101% 0, 101% 59%, 0 99%);
  min-height: 220px;
}

.sk-z {
  z-index: 99;
  top: -140px;
  left: 0;
  right: 0;
}

.w-90 {
  width: 90%;
}

.compo-heading {
  border: 2px solid #94a3f5;
  border-radius: 34px;
  padding: 13px 70px;
  font-size: 22px;
  font-weight: 600;
  width: auto;
  display: inline;
  text-align: center;
}

.text-indigo {
  color: #4a4ac6;
}

.circle {
  clip-path: circle(50% at 50% 50%);
  text-align: center;
  height: 160px;
  width: 160px;
  position: relative;
}

.cir-sea-green {
  background-color: #68c8d0;
}
.cir-sea-violet {
  background-color: #b551f4;
}
.cir-sky {
  background-color: #5193f4;
}

.cir-indigo {
  background-color: #4a4ac6;
}

.cir-war {
  background-color: #f4ad2f;
}

.cir-pink {
  background-color: #fd7bad;
}

.cir-text {
  padding-top: 32%;
  font-size: 13px;
  font-weight: 600;
  width: 100px;
  margin: 0 auto;
  line-height: 24px;
  text-transform: uppercase;
  position: absolute;
  top: 4%;
  left: 0%;
  text-align: center;
  right: 0;
  color: #fff;
}

.cir-text2 {
  padding-top: 24%;
  font-size: 13px;
  font-weight: 600;
  margin: 0 auto;
  line-height: 24px;
  text-transform: uppercase;
  position: absolute;
  top: 12%;
  left: 5%;
  transform: translate -50% -50%;
  color: #fff;
}

.tree {
  display: flex;
  flex-direction: column;
}
.aspring {
  display: flex;
  flex-direction: column;
}

.neve-highlight {
  width: 100%;
}
.neve-bg img {
  max-width: 100%;
}
.neve-none {
  display: none;
}
.blank {
  display: block;
}

.blank {
  float: left;
  height: 87px;
  background-image: url(assets/aspring-bg.png);
  background-size: cover;
  margin-top: 13px;
  background-position: center;
}

.aspring_bg {
  float: left;
  width: 100%;
  height: 87px;
  background-image: url(assets/aspring-bg.png);
  background-size: cover;
  margin-top: 13px;
  background-position: center;
}
.aspImg {
  display: none;
}

.aspImg img {
  max-width: 100%;
}

.neve-highlight div {
  flex: 1;
  margin: 0 auto;
  text-align: center;
  align-items: center;
}

.sub-head {
  width: 90%;
  margin: 0 auto;
}

.w-95 {
  width: 95%;
}

/************** sky curve ************/

.sky-curve-both {
  background-color: #5193f4;
  min-height: 500px;
  clip-path: polygon(100% 2%, 100% 94%, 0 96%, 0 4%);
  padding: 100px 0;
}

.mar-top {
  margin-top: 50px;
}

.mar-bot {
  margin-bottom: 50px;
}

.mar-topbot {
  margin-top: 50px;
  margin-bottom: 50px;
}

.pad-top {
  margin-top: 50px;
}

.pad-bot {
  margin-bottom: 50px;
}

.text-white {
  color: #fff !important;
  border-color: #fff;
}

.sec-pad-top {
  padding-top: 50px;
}

.sec-pad-topsm {
  padding-top: 20px;
}

.rounded-btn {
  border-radius: 25px;
  padding: 8px 40px;
}

.btn-warning {
  color: #000;
  background-color: #ffe200;
  border-color: #ffe200;
}

.chart {
  max-width: 250px;
}

@media (max-width: 767px) {
  .compo-heading {
    border-radius: 34px;
    padding: 13px 19px;
    font-size: 14px;
    font-weight: 600;
  }
  .row {
    display: block;
  }
  .neve-bg img {
    display: none !important;
    height: 20px;
  }
  .aspImg img {
    display: none !important;
    height: 20px;
  }

  .pt-5 {
    padding-top: 3rem !important;
  }
  .pad-top {
    margin-top: 15px;
  }
  .mb-5 {
    margin-bottom: 1rem !important;
  }
  .circle {
    height: 160px !important;
    width: 160px !important;
  }
  .right-menu {
    margin: 5px 0px;
  }
  .sky-curve-both {
    background-color: #5193f4;
    min-height: 478px;
    clip-path: polygon(100% 2%, 417% 94%, 0 99%, 0 4%);
  }
  .mar-topbot {
    margin-top: 25px;
    margin-bottom: 25px;
  }

  input.large {
    display: none;
  }
  input.small {
    display: inline-block;
  }

  /* .cardd-body{
    width: 93%; 
    border-top: 1px solid grey;
    margin-bottom: -8%;
  }
  
  
  .carddd-title{
    margin-left: 21%;  
    margin-top: -17%;
    
  }
  
  .carddddd-text {
    margin-left: 22%;
    width: 60%;
    margin-top: -5%;
   
  }
  
  .carda-img-top{
    width: 25% ;
    margin-top: -55px;
    margin-left: -6%;  
    height: 20%;
    padding-bottom: 10px;
  } */
}
.right-menu-full-width {
  margin: 0;
  background-color: white;
  padding: 20px 12px;
}

.view-responsive {
  display: flex;
}
.pie-responsive {
  display: flex;
}

@media (max-width: 480px) {
  .compo-heading {
    border-radius: 15px;
    padding: 13px 8px;
  }
  .cardd-body {
    width: 93%;
    border-top: 1px solid grey;
    margin-bottom: -8%;
  }

  .carddd-title {
    margin-left: 21%;
    margin-top: -17%;
  }

  .carddddd-text {
    margin-left: 22%;
    width: 60%;
    margin-top: -5%;
  }

  .carda-img-top {
    width: 25%;
    margin-top: -55px;
    margin-left: -6%;
    height: 20%;
    padding-bottom: 10px;
  }
}
@media (max-width: 576px) {
  .pie-responsive div {
    width: 100%;
    max-width: 300px;
  }
  .cardd-body {
    width: 93%;
    border-top: 1px solid grey;
    margin-bottom: -8%;
  }

  .carddd-title {
    margin-left: 21%;
    margin-top: -17%;
  }

  .carddddd-text {
    margin-left: 22%;
    width: 60%;
    margin-top: -5%;
  }

  .carda-img-top {
    width: 25%;
    margin-top: -55px;
    margin-left: -6%;
    height: 20%;
    padding-bottom: 10px;
  }
}

@media (max-width: 767px) {
  .view-responsive {
    flex-direction: column;
  }
  .pie-responsive {
    flex-direction: column;
  }
  .neve-highlight div {
    margin: 7px auto;
  }
  .cardd-body {
    width: 93%;
    border-top: 1px solid grey;
    margin-bottom: -8%;
  }

  .carddd-title {
    margin-left: 21%;
    margin-top: -17%;
  }

  .carddddd-text {
    margin-left: 22%;
    width: 60%;
    margin-top: -5%;
  }

  .carda-img-top {
    width: 25%;
    margin-top: -55px;
    margin-left: -6%;
    height: 20%;
    padding-bottom: 10px;
  }

  .neve-none {
    display: none;
    padding-top: 14px;
  }
  .aspImg {
    display: none;
    padding-top: 14px;
  }
}

@media (min-width: 768px) {
  .pie-responsive {
    flex-direction: row;
  }
  .cardd-body {
    width: 93%;
    border-top: 1px solid grey;
    margin-bottom: -8%;
  }

  .carddd-title {
    margin-left: 21%;
    margin-top: -17%;
  }

  .carddddd-text {
    margin-left: 22%;
    width: 60%;
    margin-top: -5%;
  }

  .carda-img-top {
    width: 25%;
    margin-top: -55px;
    margin-left: -6%;
    height: 20%;
    padding-bottom: 10px;
  }
}

@media (min-width: 992px) {
  .cardd-body {
    width: 93%;
    border-top: 1px solid grey;
    margin-bottom: -8%;
  }

  .carddd-title {
    margin-left: 21%;
    margin-top: -17%;
  }

  .carddddd-text {
    margin-left: 22%;
    width: 60%;
    margin-top: -5%;
  }

  .carda-img-top {
    width: 25%;
    margin-top: -55px;
    margin-left: -6%;
    height: 20%;
    padding-bottom: 10px;
  }
}
@media (max-width: 992px) {
  .cir-text2 {
    padding-top: 28%;
    font-size: 11px;

    top: 12%;
    left: 0;
    right: 0;
    transform: translate -50% -50%;
  }
  .circle {
    height: 130px;
    width: 130px;
  }

  .cir-text {
    font-size: 11px;
  }
  .cardd-body {
    width: 93%;
    border-top: 1px solid grey;
    margin-bottom: -8%;
  }

  .carddd-title {
    margin-left: 21%;
    margin-top: -17%;
  }

  .carddddd-text {
    margin-left: 22%;
    width: 60%;
    margin-top: -5%;
  }

  .carda-img-top {
    width: 25%;
    margin-top: -55px;
    margin-left: -6%;
    height: 20%;
    padding-bottom: 10px;
  }
}

@media (max-width: 1376px) {
  .neve-none {
    display: block;
    padding-top: 14px;
  }
  .blank {
    display: none;
  }
  .aspring_bg {
    display: none;
  }
  .aspImg {
    display: block;
    padding-top: 14px;
  }
  .cardd-body {
    width: 93%;
    border-top: 1px solid grey;
    margin-bottom: -8%;
  }

  .carddd-title {
    margin-left: 21%;
    margin-top: -17%;
  }

  .carddddd-text {
    margin-left: 22%;
    width: 60%;
    margin-top: -5%;
  }

  .carda-img-top {
    width: 25%;
    margin-top: -55px;
    margin-left: -6%;
    height: 20%;
    padding-bottom: 10px;
  }
}

.right-menu-transparent {
  margin: 0px 15px;
  background-color: transparent;
  padding: 0px 12px;
}

.bigText {
  font-size: 34px;
  display: block;
  margin-bottom: 3px;
  color: #fff;
}

.left-menu-mod {
  background-color: white;
  padding-right: 0px;
  padding-left: 40px;
  height: 1024px;
  width: 305px;
}

.right-menu-mod {
  margin: 0px 1px;
  background-color: white;
  padding: 0px 15px;
}

.right-menu-full-width-mod {
  margin: 0;
  background-color: white;
  padding: 0 12px;
}

.scrollchart {
  overflow-x: auto;
  overflow-y: hidden;
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: row;
}

/* new css */
---- .col-1 {
  flex: 0 0 auto;
  /* width: 17.666%; */
  width: 16.666%;
}

.colll {
  width: 98%;
  padding: 55px;
  /* border: 1px solid rgb(217, 212, 212); */
  margin: 20px;
}
.cardd-body {
  width: 93%;
  border-top: 1px solid grey;
  margin-bottom: -8%;
}

.carddd-title {
  margin-left: 21%;
  margin-top: -17%;
}

.carddddd-text {
  margin-left: 22%;
  width: 60%;
  margin-top: -5%;
}

.carda-img-top {
  width: 25%;
  margin-top: -55px;
  margin-left: -6%;
  height: 20%;
  padding-bottom: 10px;
}

.btnm {
  margin-left: 94%;
  border-radius: 80% 80%;
}

.btnm:hover {
  background-color: #fff;
}
.imgggg {
  width: 484px;
  height: 480px;
  margin-top: -3px;
}
.house {
  background-color: #e7e7e7;
  color: blue;
}
.tet {
  width: 447px;
  margin-left: 10%;
  margin-top: 16%;
  /* font-family: 'Droid Serif'; */
  font-size: 26px;
  font-family: Georgia, serif;
  /* font-variant: small-caps slashed-zero; */
  /* font-weight: lighter; */
}
.input-textboxx {
  width: 100%;
  padding: 2%;
  margin-bottom: 50px;
}
.input-text-boxx {
  width: 20%;
}
.label-spanx1 {
  margin-left: 25%;
  margin-top: -6%;
}
.input-text-boxx1 {
  width: 20%;
  margin-left: 25%;
  margin-top: -1%;
}
.input-text-boxx2 {
  width: 20%;
  margin-left: 50%;
  margin-top: -1%;
}
.label-spanx2 {
  margin-left: 50%;
  margin-top: -6%;
}
.input-text-box3 {
  width: 20%;
  margin-left: 75%;
  margin-top: -3%;
}
.label-spanx3 {
  margin-left: 75%;
  margin-top: -7%;
}
.btnx {
  color: #fff;

  text-align: center;

  margin-left: 35%;
  background-color: #653d22;
  border: #653d22;
  padding: 6px 40px;
  border-radius: 25px;
  margin-top: 60px;
  margin-bottom: 5%;
}
