.trending__learning__container {
  border: 1px solid green;
  height: auto !important;
  width: 100%;
  margin: auto;
  border-radius: 15px;
  padding: 20px;
  margin-bottom: 20px;
  text-align: center;
}

.trending__learning__sub__container {
  display: flex !important;
  justify-content: flex-start;
}

.trending__learning__sub__container1 {
  /* background-color: aqua; */
  width: 40%;
  height: 200px;
}

.trending__learning__sub__container2 {
  width: 30%;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: left;
  padding: 20px;
  margin-left: 20px;
  margin: 10px;
  text-align: left;
}

.trending__learning__sub__container2 p {
  font-size: 18px;
  margin: 0px;
  font-weight: 500;
  opacity: 0.7;
  color: #001103;
}

.trending__learning__sub__container3 {
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.trending__learning__img {
  width: 250px;
  height: 225px;
  border-radius: 10px;
}

.trending__learning__circle {
  height: 152px;
  width: 152px;
  border-radius: 50%;
  background: linear-gradient(to right, #20b14d, #87ce26);
  display: flex;
  align-items: center;
  justify-content: center;
}

.trending__learning__circle div {
  height: 140px;
  width: 140px;
  border-radius: 50%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.trending__learning__circle div h1 {
  color: #27963c;
}
.decHeight {
  margin-top: -105px;
}

@media (max-width: 1600px) {
  .bar__chart__container {
    flex-wrap: wrap;
  }
}
@media (max-width: 1200px) {
  .bar__chart__container {
    flex-wrap: wrap;
  }
}

@media (max-width: 992px) {
  .trending__learning__sub__container div {
    margin: 10px;
  }

  .trending__learning__sub__container {
    flex-wrap: wrap;
  }
}

@media (max-width: 768px) {
  .trending__learning__sub__container div {
    margin: 10px;
  }

  .trending__learning__sub__container {
    flex-direction: column;
  }
}

.see__more {
  display: flex;
  justify-content: space-between;
}

.see__more h6 {
  color: #27963c;
  text-decoration: underline;
}
