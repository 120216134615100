.bgimage {
  background-image: url('../../assets/homelogo.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.bgimage {
  width: 100%;
  margin: 0;
  padding: 0;
}

img.img-fluid {
  width: 11%;
  height: 170px;
}

.flex-container {
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
}

.flex-container > div {
  width: 352px;
  margin: 10px;
}

.imgfooter {
  background-color: rgb(0, 88, 255);
  height: auto;
  padding-top: 15px;
  padding-bottom: 15px;
  color: #fff;
}

.off {
  margin-top: 22px;
  color: rgb(30 29 29);
  width: 51px;
  height: 48px;
  background-color: transparent;
  border: none;
  float: right;
}

.schoolName {
  font-family: Helvetica !important;
  font-size: 50px;
}

.color {
  background-color: #b6e2d3;
  padding: 20px;
  width: auto;
}

.color2 {
  background-color: #c3c5eb;
  padding: 20px;
  width: auto;
}

.color3 {
  background-color: #feecaa;
  width: auto;
  padding: 20px;
}

.btt {
  background-color: #e69d07;
  border: 1px;
  border-radius: 15px;
}

.btt1 {
  background-color: #755aff;
  border: 1px;
  border-radius: 15px;
}

.btt3 {
  background-color: #33bd8f;
  border: 1px;
  border-radius: 15px;
}

.leraning {
  margin: 10px;
  padding: 29px;
}

.new {
  margin: 2px;
  padding: 14px;
  font-size: 13px;
}

.contents {
  margin: 0px 1px 0px 9px;
  font-family: Georgia, serif;
  font-size: 21px;
  /* width: 100px; */
}

.dynamic {
  text-align: center;
  padding: 0px;
}

/* text responsive */

/* If the screen size is 1200px wide or more, set the font-size to 80px */
@media (min-width: 1200px) {
  .responsive-font-example {
    font-size: 16px;
    text-align: center;
  }
}

/* If the screen size is smaller than 1200px, set the font-size to 80px */
@media (max-width: 1199.98px) {
  .responsive-font-example {
    font-size: 14px;
    text-align: center;
  }
}

/* text end */
.leraning a b {
  border: rgb(248, 246, 246);

  color: black;
}

.leraning :hover {
  color: #fff;
}

.hello {
  margin: 7px;
  padding: 20px;
}

@media (max-width: 767px) {
  .bgimage {
    height: 400px;
  }

  .schoolName {
    font-family: Helvetica !important;
    font-size: 27px;
    text-align: center;
  }

  .flex-container {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
  }

  .flex-container > div {
    width: 96%;
    margin: 0 auto;
    margin: 10px;
  }
}

@media (max-width: 400px) and (min-width: 360px) {
  .bgimage {
    background-image: url('../../assets/homelogo.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .leraning {
    margin: 10px;
    padding: 30px;
  }

  .new {
    margin: 6px;
    padding: 16px;
    font-size: 16px;
  }

  .dynamic,
  h6 {
    text-align: center;
    margin: 0px -480px 4px -32px;
  }

  .dynamic,
  h6 span {
    text-align: center;
    margin: 0px -261px 0px 28px;
  }

  .imgfooter {
    background-color: rgb(0, 88, 255);
    height: auto;
    padding-top: 15px;
    padding-bottom: 15px;
    width: 879px;
  }

  .off {
    margin-top: 22px;
    color: rgb(30 29 29);
    width: 51px;
    height: 48px;
    background-color: transparent;
    border: none;
  }

  .contents {
    margin: 24px -300px 0px 51px;
    font-family: Georgia, serif;
    font-size: 21px;
  }

  .responsive-font-example {
    font-size: 27px;
    text-align: center;
  }
}

@media (max-width: 567px) and (min-width: 500px) {
  .bgimage {
    background-image: url('../../assets/homelogo.jpg');
    width: 637px;
    height: 272px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .imgfooter {
    background-color: rgb(0, 88, 255);
    height: auto;
    padding-top: 15px;
    padding-bottom: 15px;
    width: 650px;
  }

  .content {
    margin: 0px;
    padding: 0px;
  }

  .links {
    display: inline-block;
    justify-content: center;
  }

  .dynamic,
  h6 {
    text-align: center;
    margin: 0px -480px 4px -32px;
  }

  .dynamic,
  h6 span {
    text-align: center;
    margin: 0px 0px 0px 18px;
  }

  .texts {
    font-size: small;
  }

  .leraning {
    margin: -15px;
    padding: 16px;
  }

  .schoolName1 {
    font-size: large;
  }

  .off {
    margin-top: 0px;
    margin-left: 586px;
    color: rgb(30 29 29);
    width: 51px;
    height: 48px;
    background-color: transparent;
    border: none;
  }
}

@media (max-width: 765px) and (min-width: 567px) {
  .bgimage {
    background-image: url('../../assets/homelogo.jpg');
    height: 240px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .imgfooter {
    background-color: rgb(0, 88, 255);
    height: auto;
    padding-top: 15px;
    padding-bottom: 15px;
    color: #fff;
  }

  .content {
    margin: 0px;
    padding: 0px;
  }

  .links {
    display: inline-block;
    justify-content: center;
  }

  .dynamic,
  h6 {
    text-align: center;
  }

  .dynamic,
  h6 span {
    text-align: center;
    margin: 0px 0px 0px 18px;
  }

  .texts {
    font-size: small;
  }

  .leraning {
    margin: -15px;
    padding: 16px;
  }

  .schoolName1 {
    font-size: large;
  }

  .off {
    margin-top: 0px;
    margin-left: 586px;
    color: rgb(30 29 29);
    width: 51px;
    height: 48px;
    background-color: transparent;
    border: none;
  }

  .contents {
    margin: 24px 0px 0px 23px;
  }

  .center {
    margin-left: 10%;
    width: 80%;
    padding: 24px;
  }
}

@media (max-width: 820px) and (min-width: 767px) {
  .bgimage {
    background-image: url('../../assets/homelogo.jpg');
    width: 872px;
    height: 240px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .imgfooter {
    background-color: rgb(0, 88, 255);
    height: auto;
    padding-top: 15px;
    padding-bottom: 15px;
    width: 887px;
  }

  .content {
    margin: 0px;
    padding: 0px;
  }

  .links {
    display: inline-block;
    justify-content: center;
  }

  .dynamic,
  h6 {
    text-align: center;
    margin: 1px 0px 0px 0px;
  }

  span {
    text-align: center;
    margin: 0px -392px 0px 37px;
    display: contents;
  }

  .texts {
    font-size: small;
  }

  .leraning {
    margin: -15px;
    padding: 16px;
  }

  .new {
    margin: 1px;
    padding: 6px;
    font-size: 12px;
  }

  .schoolName1 {
    font-size: large;
  }

  .off {
    margin-top: 32px;
    margin-left: -155px;
    color: rgb(30 29 29);
    width: 51px;
    height: 48px;
    background-color: transparent;
    border: none;
  }
}

@media (max-width: 990px) and (min-width: 819px) {
  .bgimage {
    background-image: url('../../assets/homelogo.jpg');
    width: 979px;
    height: 420px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .imgfooter {
    background-color: rgb(0, 88, 255);
    height: auto;
    padding-top: 15px;
    padding-bottom: 15px;
    width: 978px;
    margin: 0px 0px 10px 0px;
  }

  .contents {
    margin: 24px 0px 0px 23px;
  }

  .center {
    margin-left: 10%;
    width: 80%;
    padding: 24px;
  }

  .links {
    display: inline-block;
    justify-content: center;
  }

  .dynamic,
  h6 {
    text-align: center;
    margin: 3px 69px 2px -76px;
  }

  .conten {
    margin-left: 23%;
  }

  .texts {
    font-size: small;
  }

  .leraning {
    margin: -15px;
    padding: 16px;
  }

  .new {
    margin: 7px;
    padding: 11px;
    font-size: 8px;
  }

  .schoolName1 {
    font-size: xx-large;
  }

  .off {
    margin-top: 32px;
    margin-left: -70px;
    color: rgb(30 29 29);
    width: 51px;
    height: 48px;
    background-color: transparent;
    border: none;
  }

  .schoolNames {
    margin-right: 81px;
    margin-top: 11px;
  }

  .schoolName {
    margin-right: 12%;
  }

  .schoolNamess {
    margin-left: -90px;
    margin-top: 11px;
  }
}

@media (max-width: 1200px) and (min-width: 989px) {
  .bgimage {
    background-image: url('../../assets/homelogo.jpg');
    width: 1191px;
    height: 389px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .imgfooter {
    background-color: rgb(0, 88, 255);
    height: auto;
    padding-bottom: 0px;
    width: 1191px;
  }

  .content {
    margin: 0px;
    padding: 0px;
  }

  .links {
    display: inline-block;
    justify-content: center;
  }

  .dynamic,
  h6 {
    text-align: center;
    margin: -6px -75px 17px -58px;
  }

  .dynamic,
  h6 span {
    text-align: center;
    margin: 31px 30px 0px 19px;
  }

  .schoolNames {
    margin-right: -68px;
    margin-top: 27px;
  }

  .schoolName {
    margin-right: 3%;
  }

  .schoolNamess {
    margin-left: 19px;
    margin-top: 28px;
  }

  .texts {
    font-size: small;
  }

  .leraning {
    margin: 17px;
    padding: 0px;
  }

  .new {
    margin: 7px;
    padding: 13px;
    font-size: 14px;
  }

  .schoolName1 {
    font-size: xx-large;
  }

  .off {
    margin-top: 32px;
    margin-left: -155px;
    color: rgb(30 29 29);
    width: 51px;
    height: 48px;
    background-color: transparent;
    border: none;
  }

  .center {
    margin-left: 10%;
    width: 80%;
    padding: 24px;
  }
}

@media (max-width: 2000px) and (min-width: 1200px) {
  .bgimage {
    background-image: url('../../assets/homelogo.jpg');
    height: 550px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .imgfooter {
    background-color: rgb(0, 88, 255);
    height: auto;
    padding-top: 1px;
    padding-bottom: 0px;
    color: #fff;
  }

  .content {
    margin: 0px;
    padding: 0px;
  }

  .links {
    display: inline-block;
    justify-content: center;
  }

  .dynamic,
  h6 {
    position: relative;
    text-align: center;
    margin: 11px 9px 4px -6px;
  }

  .dynamic,
  h6 span {
    text-align: center;
    margin: 15px 0px 15px 0px;
  }

  .schoolName {
    margin-right: 0%;
  }

  .schoolNamess {
    margin-top: 14px;
  }

  .texts {
    font-size: small;
  }

  .leraning {
    margin: 17px;
    padding: 0px;
  }

  .new {
    margin: 7px;
    padding: 13px;
    font-size: 14px;
  }

  .schoolName1 {
    font-size: 30px;
  }

  .off {
    margin-top: 32px;
    margin-left: -50px;
    color: rgb(30 29 29);
    width: 51px;
    height: 48px;
    background-color: transparent;
    border: none;
  }

  .flex-container {
    display: flex;
    flex-wrap: nowrap;
  }

  .flex-container > div {
    width: 352px;
    margin: 10px;
    max-width: 330px !important;
  }

  .center {
    margin-left: 10%;
    width: 80%;
    padding: 24px;
  }
}

.rounded {
  border-radius: 15px !important;
}

.greenButton {
  border-color: #33bd8f;
  font-family: 'Roboto' !important;
}

.greenButton:hover {
  border-color: #33bd8f;
  background-color: #33bd8f;
  color: #fff !important;
}

.purpleButton {
  border-color: #755aff;
  font-family: 'Roboto';
}

.purpleButton:hover {
  border-color: #755aff;
  background-color: #755aff;
  color: #fff !important;
}

.yellowButton {
  border-color: #e69d07;
  font-family: 'Roboto';
}

.yellowButton:hover {
  background-color: #e69d07;
  color: #fff !important;
}

b {
  font-weight: 700;
}

.home__decription {
  width: 90%;
  border-radius: 15px;
  height: auto !important;
  background-color: #0fb160;
  margin-bottom: 20px !important;
  margin: auto;
  padding: 5px;
  text-align: center;
  color: white;
}

.home__decription p {
  color: white;
}

.home__card__heading b {
  color: #33bd8f;
}

.home__card__heading {
  font-weight: 700 !important;
  font-size: 18px !important;
}

.btn b {
  font-weight: 500;
  font-size: 16px;
}

.imgfooter::-webkit-scrollbar {
  display: none;
}

.imgfooter {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.container-fluid::-webkit-scrollbar {
  display: none;
}

.container-fluid {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.home__school__name {
  background-color: #108eec !important;
  width: 60%;
  margin: auto !important;
  border-radius: 20px;
  opacity: 0.75;
  color: #ffffff !important;
  padding: 5px;
}
