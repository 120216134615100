.title {
  color: blueviolet;
}

.scrollchart {
  overflow-x: auto;
  overflow-y: hidden;
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: row;
}
