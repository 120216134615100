@media (max-width: 1200px) {
  .bar__chart__container {
    flex-wrap: wrap;
  }
}

@media (max-width: 992px) {
  .trending__learning__sub__container div {
    margin: 10px;
  }

  .trending__learning__sub__container {
    flex-wrap: wrap;
  }
}

@media (max-width: 768px) {
  .trending__learning__sub__container div {
    margin: 10px;
  }

  .trending__learning__sub__container {
    flex-direction: column;
  }
}
