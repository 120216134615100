.main-container {
  overflow-y: scroll;
}

.left__container {
  width: 100%;
  height: 100vh;
  /* background-color: #FFFFFF; */
  display: flex;
  align-items: center;
  flex-direction: column;
}

.right__container {
  width: 80vw;
  height: 100vh;
  background-color: #ffffff;
}
