.sidebar {
  max-width: 305px;
  min-width: 175px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: auto;
}

.state__main__bar__title {
  color: #ffffff;
  margin-left: 10px;
}

.state__main__bar {
  width: 90%;
  min-width: 180px;
  height: 40px;
  min-height: 35px;
  left: 0px;
  top: 0px;
  background: linear-gradient(90deg, #27963c 0%, #87ce26 100%);
  border-radius: 22.5px;
  text-align: left;
  padding-left: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 5px;
  text-decoration: none;
}

.main__title {
  width: 300px;
  height: 19px;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #102a13;
  margin-left: 10px;
}

.sub__title {
  width: 300px;
  height: 17px;
  left: 78px;
  top: 329px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #001103;
  opacity: 0.7;
  margin-left: 40px;
}

.main__bar__nav {
  width: 90%;
  min-width: 180px;
  /* height: 40px; */
  min-height: 35px;
  left: 0px;
  top: 0px;
  /* background: linear-gradient(90deg, #1c42aa 0%, #901acf 100%); */
  border-radius: 22.5px;
  text-align: left;
  padding-left: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 5px;
  text-decoration: none;
  color: #ffffff;
}

.link-active {
  /* background: linear-gradient(90deg, #27963C 0%, #87CE26 100%); */
}

.main-link-active {
  /* background: linear-gradient(90deg, #27963C 0%, #87CE26 100%); */
  color: #27963c;
}

.main-link-active div {
  /* color: #FFFFFF; */
  color: #27963c;
}

.sub-link-active div {
  color: #27963c;
}
.sub-link-active1 div {
  color: #27963c;
}

.main__bar h4,
.main__bar_active h4 {
  display: flex;
  gap: 5px;
  height: 17px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
  flex: none;
  order: 1;
  flex-grow: 0;
  padding-left: 10px;
  text-overflow: ellipsis;
  text-decoration: none;
}

.sub__bar {
  width: 80%;
  max-width: 250px;
  min-width: 160px;
  height: 20px;
  left: 0px;
  top: 0px;
  /* background: linear-gradient(90deg, #27963C 0%, #87CE26 100%); */
  border-radius: 22.5px;
  text-align: left;
  margin-left: 15px;
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-left: 20px;
  font-size: smaller;
  text-decoration: none;
}

.sub__bar_active {
  width: 80%;
  max-width: 250px;
  min-width: 160px;
  height: 40px;
  left: 0px;
  top: 0px;
  /* background: linear-gradient(90deg, #1c42aa 0%, #901acf 100%); */
  border-radius: 22.5px;
  text-align: left;
  margin-left: 15px;
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-left: 20px;
  font-size: smaller;
  text-decoration: none;
}

.sub__bar h3,
.sub__bar_active h3 {
  display: flex;
  height: 17px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 17px;
  color: #ffffff;
  flex: none;
  order: 1;
  flex-grow: 0;
  padding-left: 10px;
  text-decoration: none;
}

.simple__header {
  display: flex;
  align-items: center;
  width: 85%;
  min-width: 180px;
  height: 40px;
  min-height: 35px;
  font-weight: 700;
  /* border-bottom: 1px solid rgb(54, 157, 95); */
}

/* .active {
    background: linear-gradient(90deg, #1c42aa 0%, #901acf 100%);
} */

.state__home__page {
  position: absolute;
  width: 305px;
  height: 1024px;
  left: 0px;
  top: 0px;

  /* text */

  /* background: #FFFFFF;
    opacity: 0.5;
    box-shadow: 1px 4px 34px rgba(255, 255, 255, 0.25); */
}
