table {
  width: 100%;
}

table th {
  background: #4a4ac6;
  color: #fff;
  text-transform: uppercase;
}

table,
th,
td {
  border: 2px solid #5193f4;
}

table,
tr,
td a {
  text-decoration: none;
}

table {
  background-color: #fff !important;
}

td:nth-child(1) {
  font-weight: normal;
}
